"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLowestPriceFn = exports.forHrs = exports["default"] = void 0;
var _react = require("react");
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var getLowestPriceFn = exports.getLowestPriceFn = function getLowestPriceFn(boat, forHrs) {
  if (boat.calculatedLowestPrice && boat.calculatedLowestPrice > 0) {
    return boat.calculatedLowestPrice;
  }
  var specialSprices = ((boat === null || boat === void 0 ? void 0 : boat.boatPriceSpecials) || []).filter(function (bps) {
    return !bps.fixed;
  }).map(function (bps) {
    return bps.boatPrice;
  }).filter(function (x) {
    return !!x;
  });
  var groupedBaseRates = [boat === null || boat === void 0 ? void 0 : boat.boatPrice].concat(_toConsumableArray(specialSprices)).map(function (bp) {
    return getLowestInRateFn(bp, forHrs);
  });
  return function (a) {
    return Math.min.apply(Math, a);
  }(groupedBaseRates);
};
var dayMatchFn = function dayMatchFn(item, dayOfWeek) {
  var dayMatch = true;
  if (dayOfWeek && item.days && item.days.length > 0) {
    dayMatch = item.days.includes(dayOfWeek);
  }
  return dayMatch;
};
var getLowestInRateFn = function getLowestInRateFn(boatPrice, forHrs, dayOfWeek) {
  if (!boatPrice) {
    return 0;
  }
  var rateItemInRange = (boatPrice.items || []).filter(function (item) {
    return !item.fixed && item.unit === "hours" && dayMatchFn(item, dayOfWeek) && item.from <= forHrs;
  }).filter(function (item) {
    return item.rate;
  }).map(function (item) {
    return item.rate;
  });
  var baseRate = boatPrice.baseHourlyRate ? boatPrice.baseHourlyRate : 0;
  return rateItemInRange.length ? Math.min.apply(Math, _toConsumableArray(rateItemInRange)) : Math.min(baseRate);
};
var forHrs = exports.forHrs = 4;
function useBoatLowestPrice(boat) {
  var _useState = (0, _react.useState)(getLowestPriceFn(boat || {}, forHrs)),
    _useState2 = _slicedToArray(_useState, 2),
    price = _useState2[0],
    setPrice = _useState2[1];
  (0, _react.useEffect)(function () {
    if (!boat) {
      return;
    }
    setPrice(getLowestPriceFn(boat, forHrs));
  }, [boat]);
  var getLowestPrice = (0, _react.useCallback)(function (boat) {
    return getLowestPriceFn(boat, forHrs);
  }, []);
  var dayMatch = (0, _react.useCallback)(dayMatchFn, []);
  var getLowestDayRate = (0, _react.useCallback)(function (boatPrice, forHrs, dayOfWeek) {
    if (!boatPrice) {
      return 0;
    }
    var dayRatesInRange = (boatPrice.items || []).filter(function (item) {
      return item.unit === "days" && dayMatchFn(item, dayOfWeek) && item.from <= Math.ceil(forHrs / 24);
    }).map(function (item) {
      return item.rate;
    });
    if (!dayRatesInRange) {
      return 0;
    }
    return dayRatesInRange.length ? function (a) {
      return Math.min.apply(Math, a);
    }(dayRatesInRange) : null;
  }, []);
  var getLowestInFixedRate = (0, _react.useCallback)(function (boatPrice, forHrs, dayOfWeek) {
    if (!boatPrice) {
      return 0;
    }
    var rateItemInRange = (boatPrice.items || []).filter(function (item) {
      return item.fixed && item.unit === "hours" && dayMatchFn(item, dayOfWeek) && Number(item.from) === Number(forHrs);
    }).map(function (item) {
      return item.rate;
    });
    return rateItemInRange.length ? function (a) {
      return Math.min.apply(Math, a);
    }(rateItemInRange) : null;
  }, []);

  // returns the lowest hourly rate * {forHrs} hours
  var getLowestInRate = (0, _react.useCallback)(getLowestInRateFn, []);
  var getBoatRate = (0, _react.useCallback)(function (cruiseDate, boat) {
    var mDate = (0, _moment["default"])(cruiseDate).clone();
    var specialRate = (boat.boatPriceSpecials || []).filter(function (r) {
      return r.boatPrice;
    }).find(function (rate) {
      if (rate.recurring) {
        // set date to year
        // set from to year
        // set to from + diff between to and from
        var year = mDate.isLeapYear() ? 2000 : 2001;
        var diff = (0, _moment["default"])(rate.to).diff(rate.from, "days");
        var d = mDate.set("year", year);
        var from = (0, _moment["default"])(rate.from).set("year", year);
        var to = (0, _moment["default"])(rate.from).set("year", year).add(diff, "days");
        return d.isBetween(from, to);
      }
      return mDate.isBetween(rate.from, rate.to);
    });
    return (specialRate === null || specialRate === void 0 ? void 0 : specialRate.boatPrice) || boat.boatPrice;
  }, []);
  var getRate = (0, _react.useCallback)(function (cruiseDate, boat, hours) {
    var boatPrice = getBoatRate(cruiseDate, boat);
    var dayOfWeek = (0, _moment["default"])(cruiseDate).format("dddd").toLowerCase();
    var lowestHrRate = getLowestInRate(boatPrice, hours, dayOfWeek);
    var lowestFixedHrRate = getLowestInFixedRate(boatPrice, hours, dayOfWeek);
    var lowestDayRate = getLowestDayRate(boatPrice, hours, dayOfWeek);
    var totalDayRate = lowestDayRate * Math.ceil(hours / 24);
    var totalHourRate = lowestFixedHrRate || lowestHrRate * hours;
    return totalDayRate !== 0 && totalDayRate < totalHourRate ? totalDayRate : totalHourRate;
  }, []);
  return {
    price: price,
    getLowestPrice: getLowestPrice,
    getLowestInRate: getLowestInRate,
    getLowestDayRate: getLowestDayRate,
    getRate: getRate,
    getLowestInFixedRate: getLowestInFixedRate,
    getBoatRate: getBoatRate
  };
}
var _default = exports["default"] = useBoatLowestPrice;